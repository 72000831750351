<template>
  <div class="app v">
    <van-pull-refresh v-model="isRefreshing" @refresh="onRefresh" class="flex" style="overflow-y: auto;">
      <template v-if="detail">
        <div class="padding-08">
          <div class="padding-08 bold fc-t">
            <span>{{ detail.proName }} / {{ detail.stageName }}</span>
            <br />
            <span class="fs-huge">{{ detail.name }}</span>
          </div>
          <div class="padding-08 fc-c">{{ detail.info || '暂无任务目标' }}</div>
        </div>
        <van-grid :column-num="3">
          <van-grid-item :to="`/project/task/experiment/${detail.id}`">
            <count-to title="实验记录数" :end-val="detail.expNumber" value-class="fs-huge" />
          </van-grid-item>
          <van-grid-item :to="`/project/task/progress/${detail.id}`">
            <count-to title="进度记录数" :end-val="detail.records ? detail.records.length : 0" value-class="fs-huge" />
          </van-grid-item>
          <van-grid-item :to="`/project/task/risk/${detail.id}`">
            <count-to title="风险记录数" :end-val="detail.riskNumber" value-class="fs-huge" />
          </van-grid-item>
        </van-grid>
        <van-cell-group>
          <van-cell title="任务进度">
            <div class="h c">
              <van-progress color="#607ae3" :percentage="detail.progress*100" :show-pivot="false" class="flex" />
              <span class="no-flex fs-small gap-l-small">{{Math.round(detail.progress*10000) / 100}}%</span>
            </div>
          </van-cell>
          <van-cell title="阶段状态">
            <van-tag v-if="detail.status == 'WAITTING'">待开始</van-tag>
            <van-tag type="primary" v-else-if="detail.status == 'RUNING'">进行中</van-tag>
            <van-tag type="success" v-else-if="detail.status == 'FINISH'">已完成</van-tag>
            <van-tag type="danger" v-else-if="detail.status == 'CANCEL'">已取消</van-tag>
          </van-cell>
          <van-cell title="执行人员">
            <template #label>
              <staff-wall type="task" :target-id="detail.id" show-total class="gap-10" />
            </template>
          </van-cell>
          <van-cell title="计划开始时间">{{ detail.planBegTime }}</van-cell>
          <van-cell title="计划结束时间">{{ detail.planEndTime }}</van-cell>
          <van-cell title="实际开始时间">{{ detail.realBegTime || "暂无实际开始时间"}}</van-cell>
          <van-cell title="实际结束时间">{{ detail.realEndTime || '暂无实际结束时间' }}</van-cell>
        </van-cell-group>
      </template>
    </van-pull-refresh>
    <div class="padding-15 no-flex">
      <van-button type="info" round style="width: 100%;" @click="toAddProgress(detail.id)">新增进度</van-button>
    </div>
  </div>
</template>

<script>
import { getTaskById } from "@/api/project/task";
import StaffWall from "../../assembly/wall";
export default {
  components: { StaffWall },
  data() {
    return {
      taskId: null,
      detail: null,
      projectTaskStatusEnum: {
        WAITTING: { id: 0, key: "WAITTING", name: "待开始", type: "info" },
        RUNING: { id: 1, key: "RUNING", name: "进行中", type: "primary" },
        FINISH: { id: 2, key: "FINISH", name: "已完成", type: "success" },
        CANCEL: { id: 3, key: "CANCEL", name: "已取消", type: "danger" },
      },
      typeEnum: {
        info: "fc-g",
        primary: "fc-p",
        success: "fc-s",
        danger: "fc-e",
      },
      currentProcess: 0,
      rate: 0,
      isRefreshing: false,
    };
  },

  computed: {
    text() {
      return Math.floor(this.rate * 100) / 100 + "%";
    },
  },
  mounted() {
    this.detail = null;
    this.taskId = this.$route.params.id;
    this.getTaskInfo();
  },
  methods: {
    onRefresh() {
      // 下拉刷新数据
      this.detail = null;
      this.getTaskInfo();
      this.isRefreshing = false;
    },
    getTaskInfo() {
      getTaskById(this.taskId).then((res) => {
        this.detail = res || {};
        this.rate = res.progress * 100;
      });
    },
    toExpList(id) {
      this.$router.push({
        name: "ProjectExp",
        params: {
          taskId: id,
        },
      });
    },
    toProgressList(id) {
      this.$router.push({
        name: "ProjectProgress",
        params: {
          taskId: id,
        },
      });
    },
    toRiskList(id) {
      this.$router.push({
        name: "ProjectTasksRisk",
        params: {
          taskId: id,
        },
      });
    },
    toAddProgress(id) {
      this.$router.push({
        name: "ProjectProgressAdd",
        params: {
          taskId: id,
        },
      });
    },
  },
};
</script>